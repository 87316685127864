import "./AlbumPhoto.css";
const AlbumPhoto = (props) => {
  return (
    <div className="allAlbumPhotoContainer">
      <div className="container1AlbumPhoto">
        <img className="imgAlbumPhoto" alt="Plat de sushi au saumon frais, avec des tranches de saumon rose et brillant" src={props.c1img1} />
        <img className="imgAlbumPhoto" alt="Assiette gastronomique avec une belle pièce de viande, posée sur une feuille verte. La viande est cuite à la perfection, et elle est entourée de points de sauce aux couleurs vives. Les points de sauce sont disposés de manière artistique, et ils créent un effet visuel unique." src={props.c1img2} />
        <img className="imgAlbumPhoto" alt="Bol de velouté orangé, avec une texture lisse et soyeuse." src={props.c1img3} />
      </div>
      <div className="container2AlbumPhoto">
        <img className="imgAlbumPhoto" alt="Une belle pièce de viande, cuite à point, est servie dans une assiette avec de la purée de pommes de terre et quelques tomates séchées. " src={props.c2img1} />
        <img className="imgAlbumPhoto" alt="Une assiette de brochettes de légumes, composées de carottes, d'oignons rouges, de courgettes et de tomates. Les brochettes sont dorées et croustillantes, et les légumes sont tendres et savoureux. Les tranches de légumes sont disposées autour des brochettes, et elles apportent une touche de couleur et de fraîcheur au plat." src={props.c2img2} />
      </div>
    </div>
  );
};
export default AlbumPhoto;
