import GetFormulesDuJourEntrees from "../../componants/getformulesdujourentrees/GetFormulesDuJourEntrees";
import GetFormulesDuJourPoissons from "../../componants/getformulesdujourpoissons/GetFormulesDuJourPoissons";
import GetFormulesDuJourViandesDuJour from "../../componants/getformulesdujourviandesdujour/GetFormulesDuJourViandesDuJour";
import GetFormulesDuJourGarniture from "../../componants/getformulesdujourgarnitures/GetFormulesDuJourGarnitures";
import GetFormulesDuJourDesserts from "../../componants/getformulesdujourdesserts/GetFormulesDuJourDesserts";
import plat1 from "../../images/boeuf.png"
import poisson from "../../images/plats2.png"
import "./PageNosPlats.css";
const PageNosPlats = () => {
  return (
    <div className="allPageNosPlats">
      <div className="blocPlatsContainer">
        <div className="bloc1Plats">
          <GetFormulesDuJourEntrees />
          <GetFormulesDuJourPoissons />
          <GetFormulesDuJourViandesDuJour />
          <GetFormulesDuJourGarniture />
          <GetFormulesDuJourDesserts />
        </div>
        <div className="grandeImageContainer">
          <img
            className="grandeImagePlats"
            src={plat1}
            alt="img"
          />
          <img
            className="grandeImagePlats"
            src={poisson}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
};
export default PageNosPlats;
