import React, { useState } from "react";
import logo from "../../images/grandLogo.jpg";
import "./MenuOuvrant.css";
const MenuOuvrant = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="menu-container">
      <div
        className={`menu-icon ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`menu-nav ${isMenuOpen ? "open" : ""}`}>
        <ul className="ulMenuOuvrant">
          <div
            className={`menu-icon ${isMenuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <li>
            <a href="/">
              <span>Accueil</span>
            </a>
          </li>
          <li>
            <a href="/menus">
              <span>À la carte</span>
            </a>
          </li>
          <li>
            <a href="/nosplats">
              <span>Nos plats</span>
            </a>
          </li>
          <li>
            <a href="/lerestaurant">
              <span>Le restaurant</span>
            </a>
          </li>
          <li>
            <a href="/contact">
              <span>Contact</span>
            </a>
          </li>
        </ul>
        <div className="filtreMenuOuvrant">
          <img className="logoMenuOuvrant" src={logo} alt="img menuOuvrant" />
        </div>
      </nav>
    </div>
  );
};
export default MenuOuvrant;
