import "./Footer.css";
import logo from "../../images/grandLogo.jpg";
import map from "../../images/footermap.png";
import autorisation from "../../images/nonautorise3.png";
const Footer = () => {
  return (
    <div className="allFooterContainer">
      <div className="part1FooterContainer">
        <div className="h1H2FooterContainer">
          <h1 className="h1Footer">Les Bains Douches</h1>
          <h2 className="h2Footer">Christophe Cazalis</h2>
        </div>
        <div className="logoFooterContainer">
          <img className="logoFooter" src={logo} alt="logo du restaurant les bains douches avec une toque de cuisinier le nom du restaurant et le nom du chef du restaurant Christophe cazalis" />
        </div>
      </div>
      <div className="part2FooterContainer">
        <div className="contactFooterContainer">
          <h3 className="h3Footer">Contactez-Nous</h3>
          <p className="titreFooter">Adresse</p>
          <p className="infoFooter">RUE DU RAMPART DU TOURONC, 09100 Pamiers</p>
          <p className="titreFooter">Contact</p>
          <p className="infoFooter">fixe: 05 61 69 78 27</p>
          <div className="autorisationContainer">
            <img className="autorisation" src={autorisation} alt="Panneau d'interdiction aux animaux dans un restaurant. Le panneau est circulaire et rouge, avec un pictogramme représentant un chien barré d'un trait rouge en diagonal. Le texte du panneau indique Animaux interdits." />
          </div>
            <a className="aMentionsLegales" href="/mentionslegales"><p className="pMentionsLegales">Mentions légales</p></a>
            <div className="lienDvpAriegeContainer">
            <p className="pLienDvpAriege">Création de site internet avec</p> <a className="aLienDvpAriege" href="https://www.dvpariege.fr/">dvpariege</a>
            </div>
        </div>
        <div className="imgMapFooterContainer">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.fr/maps/place/Les+Bains+Douches/@43.1139641,1.6100389,17z/data=!3m1!4b1!4m6!3m5!1s0x12af1c3a179b006b:0xb1880c8365cbeea!8m2!3d43.1139641!4d1.6126138!16s%2Fg%2F1thnf1n0?entry=ttu"
          >
            <img className="imgFooter" src={map} alt="img google map qui indique l'emplacement du restaurant LesBainsDouches à Pamiers, en Ariège. " />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
