import "./CartesInformative.css";
import { Fade } from "react-awesome-reveal";

const CartesInformative = (props) => {
  return (
    <div className="allCarteInformative">
      <Fade direction="up">
      <div className="carteInformative1">
        <img className="imgCarteInformative" src={props.img1} alt="Une personne au marché qui achète des légumes frais, salades, carottes, choux rouges" />
        <p className="pCarteInformative">{props.info1} </p>
      </div>
      </Fade>
      <Fade direction="up">
      <div className="carteInformative1">
        <img className="imgCarteInformative" src={props.img2} alt="Salle du restaurant les bains douches à Pamiers avec les menus sur les tables " />
        <p className="pCarteInformative">{props.info2} </p>
      </div>
      </Fade>
      <Fade direction="up">
      <div className="carteInformative1">
        <img className="imgCarteInformative" src={props.img3} alt="L'entrée du restaurant les bains douches avec toute une sélection de bouteilles de vins " />
        <p className="pCarteInformative">{props.info3} </p>
      </div>
      </Fade>
    </div>
  );
};
export default CartesInformative;
