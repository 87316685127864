import "./App.css";
import { Route, Routes } from "react-router-dom";
import MenuOuvrant from "../src/componants/menuouvrant/MenuOuvrant";
import PageNosPlats from "./pages/pagenosplats/PageNosPlats";
import PageLeRestaurant from "./pages/pagelerestaurant/PageLeRestaurant";
import PageContact from "./pages/pagecontact/PageContact";
import PageNosMenu from "./pages/pagenosmenus2/PageNosMenu2";
import PageAccueil from "./pages/pageaccueil/PageAccueil";
import Footer from "./componants/footer/Footer";
import PageMentionsLegales from "./pages/pagementionslegales/PageMentionsLegales";
function App() {
  return (
    <div className="App">
      <header className="App-header">   
      <MenuOuvrant />     
        <Routes>
          <Route exact path="/" element={<PageAccueil />} />
          <Route exact path="/nosplats" element={<PageNosPlats />} />
          <Route exact path="/lerestaurant" element={<PageLeRestaurant />} />
          <Route exact path="/contact" element={<PageContact />} />
          <Route exact path="/menus" element={<PageNosMenu />} />
          <Route exact path="/mentionslegales" element={<PageMentionsLegales />} />
        </Routes>
      </header>
      <Footer />
    </div>
  );
}

export default App;
