import React from "react";
import "./CarteSlide.css";
import Accueil from "../../images/Accueil.JPG";
const CarteSlide = () => {
  return (
    <div className="allCarteSlide">
      <div className="animation-element">
        <p className="textSlide">Bienvenue</p>
      </div>
      <div className="animation-element2">
        <img className="imgSlider" src={Accueil} alt="img" />
        <p className="text2Slide">Dans notre Restaurant</p>
      </div>
    </div>
  );
};

export default CarteSlide;
