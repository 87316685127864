import "./GetFormulesDuJourEntrees.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import entree from "../../images/plats5.jpg";
const GetFormulesDuJourEntrees = () => {
  const [formulesdujour, setFormulesDuJour] = useState([]);

  useEffect(() => {
    //requete
    axios
      .get("http://localhost:5000/formulesdujour")
      .then((response) => {
        console.log(response.data);
        const formulesdujourData = response.data;
        const formulesdujour = formulesdujourData.map(
          ({ id, nom, categorie }) => ({ id, nom, categorie })
        );
        setFormulesDuJour(formulesdujour);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //ajouter

  return (
    <div className="all">
      <div className="touteLaFormuleContainer">
        <h2 className="h2Entrees">Entrées</h2>
        <p className="plocal">Tarte fine de bleu aux poires et pain d'épices</p>
        <p className="plocal">Soupe de poissons</p>
        <p className="plocal">Jambon pa amb tomaquet</p>
        <p className="plocal">Endives rôties et sa crème safran orange</p>
        <ul className="ulFormuleEntrees">
          {formulesdujour
            .filter((formuledujour) => formuledujour.categorie === "entrees") // Filtrer les formules avec la catégorie 'entrees'
            .map((formuledujour) => (
              <li className="liFormule" key={formuledujour.id}>
                <div className="descriptionFormuleContainer">
                  <h2 className="nomDeLaFormule">{formuledujour.nom}</h2>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className="imgFormuleEntreesContainer">
        <img className="imgFormuleEntrees" src={entree} alt="img" />
      </div> 
    </div>
  );
};

export default GetFormulesDuJourEntrees;
