import "./PageNosMenus2.css";
import { Fade } from "react-awesome-reveal";
import axios from "axios";
import React, { useState, useEffect } from "react";
import plats1 from "../../images/menu2/imgplats1.png";
import plats2 from "../../images/menu2/imgplats2.png";
import plats3 from "../../images/menu2/imgplats3.png";
import CartesInformative from "../../componants/cartesinformative/CartesInformative";
import CarteSlide from "../../componants/carteslide/CarteSlide";
import exterieur from "../../images/exterieur.JPG";
import imgvins from "../../images/vins.JPG";
import produitsfrais from "../../images/produitsfrais.png";
const PageNosMenus2 = () => {
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    //requete
    axios
      .get("http://localhost:5000/menus")
      .then((response) => {
        console.log(response.data);
        const nosmenusData = response.data;
        const menus = nosmenusData.map(({ id, nom, description, prix }) => ({
          id,
          nom,
          description,
          prix,
        }));
        setMenus(menus);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="allPageNosPlats2">
      <CarteSlide />
      <div className="imgTitreMenu2">
        <img className="imgBloc1Menu" src={plats1} alt="img" />
        <h2 className="h2Menu2">
          Découvrez nos formules et menus enfants, spécialement conçus pour
          ravir les petits gourmets. Des plats équilibrés, frais et délicieux,
          préparés avec soin. Une expérience culinaire inoubliable pour toute la
          famille !
        </h2>
      </div>
      <div className="bloc1MenuContainer">
        <div className="nosMenusContainer">
          <h2 className="h2Menus">Les Fomules</h2>
          <p className="plocal">Entrée + viande ou poisson OU viande ou poisson + dessert (Cette formule est servie uniquement le midi sauf week-end et</p>
          <p className="plocal">15€</p>
          <p className="plocal">Entrée + viande ou poisson + dessert</p>
          <p className="plocal">20€</p>
          <p className="plocal">Entrée + poisson + viande + dessert</p>
          <p className="plocal">28€</p>
          <p className="plocal">Gourmande ( entrée + poisson + viande + dessert à la carte)</p>
          <p className="plocal">39€</p>
          <p className="plocal">Gourmande 2 (entrée + poisson OU viande + dessert à la carte)</p>
          <p className="plocal">34€</p>
          <h2 className="h2Menus">Menu Enfant</h2>
          <p className="plocal">Viande ou poisson + dessert (Enfants de moins de 10 ans, menu à composer dans le menu du jour)</p>
          <p className="plocal">10€</p>
          <ul className="ulMenus">

            {menus.map((menu) => (
              <li className="liMenus" key={menu.id}>
                <div className="titreDuMenuContainer">
                  <h2 className="titreDuMenu">{menu.nom}</h2>
                </div>
                <div className="descriptionMenusContainer">
                  <p className="descriptionDuMenu">{menu.description}</p>
                </div>
                <div className="prixDuMenuContainer">
                  <p className="prixDuMenu">{menu.prix}€</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bloc2MenuContainer">
        <img className="imgBloc1MenuBrochette" src={plats2} alt="img" />
        <img className="imgBloc1MenuSalade" src={plats3} alt="img" />
      </div>
      <Fade direction="right">
      <h2 className="h2CarteInfo">
        {" "}
        Qu'est-ce qui rend notre restaurant si attrayant ?{" "}
      </h2>
      </Fade>
      <CartesInformative
        img1={produitsfrais}
        info1="Des produits frais et de saisons pour vous offrir des produits de qualité toute l'année "
        img2={exterieur}
        info2="Un cadre agréable pour profiter au mieux de votre repas dans notre restaurant. Notre salle climatisée vous permettra de passer un moment agréable, même en été. "
        img3={imgvins}
        info3="Découvrez notre vaste choix de vins sélectionnés avec soin. Un paradis pour les amateurs de vin. À votre santé ! "
      />
      <div className="plusinfoContainer">
        <a className="aPlusInfo" href="/lerestaurant">
          <p className="pPLusInfo">plus d'info sur notre restaurant ici</p>
        </a>
      </div>
    </div>
  );
};
export default PageNosMenus2;
