import "./SelectionMenu.css";
import { Fade } from "react-awesome-reveal";

const SelectionMenu = () => {
  return (
    <div className="allSelectionMenu">
      <Fade cascade damping={0.2}>
      <a href="/menus" className="aSelectionMenu">
        <div className="menu1" aria-label="Assiette de salade avec trois œufs." draggable={false}>
          <h2 className="h2Menu">À la carte</h2>
        </div>
      </a>
      <a href="/nosplats" className="aSelectionMenu">
        <div className="menu2" aria-label="menu d'un restaurant" draggable={false}>
          <h2 className="h2Menu">Formule du jour</h2>
        </div>
      </a>
      <a href="/lerestaurant" aria-label="salle d'un restaurant vide avec les menus sur les tables" className="aSelectionMenu">
        <div className="menu3" draggable={false}>
          <h2 className="h2Menu">Le Restaurant</h2>
        </div>
      </a>
      </Fade>
    </div>
  );
};
export default SelectionMenu;
