import "./PageAccueil.css";
import SelectionMenu from "../../componants/selectionmenu/SelectionMenu";

const PageAccueil = () => {
  return (
    <div className="allPageAccueil">
      <div className="introAccueil">
        <div className="filtreAccueilContainer">
          <h1 className="h1PageAccueil">Les bains douches </h1>
          <h2 className="h2PageAccueil">produits frais de la région</h2>
          <h3 className="h3PageAccueil">Christophe Cazalis</h3>
        </div>
        <SelectionMenu />
      </div>
    </div>
  );
};
export default PageAccueil;
