import "./GetFormulesDuJourDesserts.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import entree from "../../images/poirechocolat.png"
const GetFormulesDuJourDesserts = () => {
  const [formulesdujour, setFormulesDuJour] = useState([]);

  useEffect(() => {
    //requete
    axios
      .get("http://localhost:5000/formulesdujour")
      .then((response) => {
        console.log(response.data);
        const formulesdujourData = response.data;
        const formulesdujour = formulesdujourData.map(
          ({ id, nom, categorie }) => ({ id, nom, categorie })
        );
        setFormulesDuJour(formulesdujour);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //ajouter

  return (
    <div className="all">
      <div className="touteLaFormuleContainer">
        <h2 className="h2Entrees">Desserts</h2>
        <p className="plocal">Sablé mandarine</p>
        <p className="plocal">Verrine caramel et ananas frais</p>
        <p className="plocal">Salade de fruits (fruits frais et sirop)</p>
        <p className="plocal">Bavarois chocolat et sa feuillantine</p>
        <p className="plocal">Fromage</p>
        <ul className="ulFormuleDesserts">
          {formulesdujour
            .filter((formuledujour) => formuledujour.categorie === "desserts") // Filtrer les formules avec la catégorie 'entrees'
            .map((formuledujour) => (
              <li className="liFormule" key={formuledujour.id}>
                <div className="descriptionFormuleContainer">
                  <h2 className="nomDeLaFormule">{formuledujour.nom}</h2>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className="imgFormuleEntreesContainer">
        <img
          className="imgFormuleEntrees"
          src={entree}
          alt="img"
        />
      </div>
    </div>
  );
};

export default GetFormulesDuJourDesserts;
