import "./GetFormulesDuJourGarnitures.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import garniture from "../../images/plats3.png"
const GetFormulesDuJourGarniture = () => {
  const [formulesdujour, setFormulesDuJour] = useState([]);

  useEffect(() => {
    //requete
    axios
      .get("http://localhost:5000/formulesdujour")
      .then((response) => {
        console.log(response.data);
        const formulesdujourData = response.data;
        const formulesdujour = formulesdujourData.map(
          ({ id, nom, categorie }) => ({ id, nom, categorie })
        );
        setFormulesDuJour(formulesdujour);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //ajouter

  return (
    <div className="all">
      <div className="touteLaFormuleContainer">
        <h2 className="h2Entrees">Garnitures</h2>
        <p className="plocal">Pomme de terre du jour</p>
        <p className="plocal">Poêlée de légumes</p>
        <p className="plocal">Salade du moment</p>
        <p className="plocal">Supplément garnitures 2€</p>

        <ul className="ulFormuleGarnitures">
          {formulesdujour
            .filter((formuledujour) => formuledujour.categorie === "garnitures") // Filtrer les formules avec la catégorie 'garnitures'
            .map((formuledujour) => (
              <li className="liFormule" key={formuledujour.id}>
                <div className="descriptionFormuleContainer">
                  <h2 className="nomDeLaFormule">{formuledujour.nom}</h2>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className="imgFormuleEntreesContainer">
        <img
          className="imgFormuleEntrees"
          src={garniture}
          alt="img"
        />
      </div>
    </div>
  );
};

export default GetFormulesDuJourGarniture;
