import AlbumPhoto from "../../componants/albumphoto/AlbumPhoto";
import "./PageLeRestaurant.css";
import chef from "../../images/chef.png";
import plat1 from "../../images/plats1.png"
import plat2 from "../../images/plats2.png"
import plat3 from "../../images/plats3.png"
import plat4 from "../../images/plats4.png"
import plat5 from "../../images/plats5.jpg"
const PageLeRestaurant = () => {
  return (
    <div className="allPageLeRestaurant">
      <div className="leRestaurantTexteImageContainer">
        <p className="pDescriptionLeRestaurant">
          <h1 className="h1LeRestaurant">L'histoire du restaurant</h1>
          C’est au retour d’un enfant du pays : Christophe Cazalis et de sa
          compagne Béatrice que nous devons la reprise en 2008 du restaurant Les
          Bains Douches à Pamiers. L’arrivée du couple a été synonyme pour cet
          établissement de charme d’un grand coup de frais ; en effet, ils ont
          eu à cœur de rénover, embellir et décorer … bref ils ont su exploiter
          au mieux ce cadre si sympathique ! Côté salle, Béatrice vous accueille
          avec le charme et la gentillesse qui la caractérise. Elle sait en
          quelques mots saupoudrés d’un sourire, faire de sa salle de restaurant
          un lieu convivial où chacun se sent immédiatement à l’aise. Côté
          cuisine, c’est Christophe qui officie avec ferveur et uniquement des
          produits frais, régionaux et de saison (il y tient !). Ce grand
          bonhomme passionné concocte chaque jour des plats à la fois savoureux,
          inventifs mais sans prétentions inutiles. Sa cuisine s’inscrit dans la
          tradition française tout en se permettant des digressions gourmandes
          pour le bonheur de tous les palais ! Le couple a su s’entourer d’une
          petite équipe de professionnels pour les seconder au mieux dans leur
          entreprise et faire des Bains Douches l’un des restaurants de Pamiers
          « qui compte » ! Nous vous invitons donc à venir en été profiter de
          l’ombre et de la fraîcheur du canal sur notre terrasse fleurie ou bien
          en hiver à venir vous réchauffer les papilles dans notre confortable
          salle de restaurant. En toute saison, venez donc goûter aux charmes
          des Bains Douches… vous ne serez pas déçus !
        </p>
        <img
          src={chef}
          className="imgChefRestaurant"
          alt="Un chef de cuisine souriant portant une tenue de chef de cuisine blanche, Il porte une toque et une veste de cuisine,"
        />
      </div>
      <AlbumPhoto
        c1img1={plat1}
        c1img2={plat2}
        c1img3={plat3}
        c2img1={plat4}
        c2img2={plat5}
      />
    </div>
  );
};
export default PageLeRestaurant;
