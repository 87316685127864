import "./PageContact.css";

const PageContact = () => {
  return (
    <div className="allPageContact">
      <div className="blocContainer">
        <div className="bloc1Contact">
          <h1 className="h1Contact">CONTACTEZ-NOUS</h1>
          <h2 className="h2Contact">ADRESSE</h2>
          <p className="pContact">1 rue rem du touronc, 09100 pamiers</p>
          <h2 className="h2Contact">CONTACT</h2>
          <p className="pContact">fixe: 05 61 69 78 27</p>
        </div>
        <div className="bloc2Contact">
          <h2 className="h2HorairesContact">Horaires</h2>
          <p className="pHoraires">
            Lundi <br /> <br /> de 12h00 à 14h00 et de 18h00 à 23h00
          </p>
          <p className="pHoraires">
            Jeudi <br /> <br /> de 12h00 à 14h00 et de 19h00 à 22h00
          </p>
          <p className="pHoraires">
            Vendredi <br /> <br /> de 12h00 à 14h00 et de 19h00 à 22h00
          </p>
          <p className="pHoraires">
            Samedi <br /> <br /> de 12h00 à 14h00 et de 19h00 à 22h00
          </p>
          <p className="pHoraires">
            Dimanche <br /> <br /> de 12h00 à 14h00 et de 19h00 à 22h00
          </p>
        </div>
      </div>
    </div>
  );
};
export default PageContact;
