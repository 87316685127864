import "./GetFormulesDuJourViandesDuJour.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import boeuf from "../../images/boeuf.png";

const GetFormulesDuJourViandesDuJour = () => {
  const [formulesdujour, setFormulesDuJour] = useState([]);

  useEffect(() => {
    //requete
    axios
      .get("http://localhost:5000/formulesdujour")
      .then((response) => {
        console.log(response.data);
        const formulesdujourData = response.data;
        const formulesdujour = formulesdujourData.map(
          ({ id, nom, categorie }) => ({ id, nom, categorie })
        );
        setFormulesDuJour(formulesdujour);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //ajouter

  return (
    <div className="all">
      <div className="touteLaFormuleContainer">
        <h2 className="h2Entrees">Viandes</h2>
        <p className="plocal">Bas carré de veau</p>
        <p className="plocal">Cuisse de canard confite par nos soins</p>
        <p className="plocal">Sûpreme de volaille</p>
        <p className="plocal">Cassoulet maison</p>
        <p className="plocal">Onglet de boeuf (supp 3.50€)</p>
        <p className="plocal">Sauce pour les viandes: poivre, Maury, bonne femme
</p>
        <ul className="ulViandesContainer">
          {formulesdujour
          .filter(
              (formuledujour) => formuledujour.categorie === "viandesdujour"
            ) // Filtrer les formules avec la catégorie 'entrees'
            .map((formuledujour) => (
              <li className="liFormule" key={formuledujour.id}>
                <div className="descriptionFormuleContainer">
                  <h2 className="nomDeLaFormule">{formuledujour.nom}</h2>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className="imgFormuleEntreesContainer">
        <img
          className="imgFormuleEntrees"
          src={boeuf}
          alt="img"
        />
      </div>
    </div>
  );
};

export default GetFormulesDuJourViandesDuJour;
